import {
  GlobalSectionsQueryEN,
  GlobalSectionsQueryFR,
  GlobalSectionsQueryDE,
  GlobalSectionsQueryES,
  GlobalSectionsQueryPL,
} from '@/graphql/GlobalSectionsQueries.js'
import {
  GlobalContactQueryEN,
  GlobalContactQueryFR,
  GlobalContactQueryDE,
  GlobalContactQueryES,
  GlobalContactQueryPL,
} from '@/graphql/GlobalContactQueries.js'

export const state = () => ({
  showCalendlyPopup: false,
  language: 'en',
  globalSectionsData: null,
  globalContactData: null,
  dataFetching: true,
  langToChange: 'en',
  enabledLocales: [],
})

export const getters = {
  isCalendlyPopupVisible(state) {
    return state.showCalendlyPopup
  },
  getCurrentLanguage(state) {
    return state.language
  },
  getLangToChange(state) {
    return state.langToChange
  },
  getCaseStudiesSectionData(state) {
    return state?.globalSectionsData?.[state.language]?.caseStudies
  },
  getCustomersSectionData(state) {
    return {
      clients: { ...state?.globalSectionsData?.clients },
      ...state?.globalSectionsData?.[state.language]?.ourCustomers,
    }
  },
  getWebSolutionsSectionData(state) {
    return state?.globalSectionsData?.[state.language]?.webSolutions
  },
  getPrivacyPolicyData(state) {
    return state?.globalSectionsData?.[state.language]?.privacyPolicyAndCookies
  },
  getNavigationData(state) {
    return state?.globalSectionsData?.general?.navigation
  },
  getGeneralContent(state) {
    return {
      requestADemo: state?.globalSectionsData?.general?.requestADemo[state.language],
    }
  },
  getMapSectionContent(state) {
    return state?.globalSectionsData?.[state.language]?.mapSection
  },
  getMeetingSectionContent(state) {
    return state?.globalSectionsData?.[state.language]?.acfMeeting
  },
  getContactData(state) {
    return state?.globalContactData
  },
  getMeetingModalTitle(state) {
    return state?.globalContactData?.meetingModalTitle[state.language]
  },
  meetingModalSubtitle(state) {
    return state?.globalContactData?.meetingModalSubtitle[state.language]
  },
  getDataFetching(state) {
    return state.dataFetching
  },
}

export const actions = {
  setCalendlyPopupVisibility({ commit }, value) {
    commit('SET_SHOW_CALENDLY_POPUP_STATE', value)
  },
  setLanguage({ commit, state }, value) {
    commit('SET_LANG_TO_CHANGE', value.lang)
    if (this.$router.currentRoute.name === value.name) return
    this.$router.replace({ name: value.name })
  },
  async getGlobalSectionData({ state, commit }) {
    const global = await this.$graphql.default.request(getQueryByLanguage(state.langToChange, true))

    commit('SET_GLOBAL_SECTIONS_DATA', global?.globalSections?.globalSections)
    commit('SET_LANGUAGE', state.langToChange)
  },
  async getGlobalContactData({ state, commit }) {
    const global = await this.$graphql.default.request(
      getQueryByLanguage(state.langToChange, false)
    )

    commit('SET_GLOBAL_CONTACT_DATA', global?.contactOptions?.contactOptions)
    commit('SET_LANGUAGE', state.langToChange)
  },
  async getGlobalData({ state, dispatch, commit }) {
    await dispatch('getGlobalSectionData')
    await dispatch('getGlobalContactData')
    await commit('SET_DATA_FETCHING', false)
    const langs = []

    if (state.globalSectionsData?.languages?.english) {
      langs.push('en')
    }

    if (state.globalSectionsData?.languages?.polish) {
      langs.push('pl')
    }

    if (state.globalSectionsData?.languages?.french) {
      langs.push('fr')
    }

    if (state.globalSectionsData?.languages?.german) {
      langs.push('de')
    }

    if (state.globalSectionsData?.languages?.spanish) {
      langs.push('es')
    }

    commit('SET_ENABLED_LOCALES', langs)
  },
  async nuxtServerInit({ context, dispatch }, { route }) {
    const routeName = route.meta[0]?.slug || 'homepage'
    const routeLang = route.meta[0]?.lang || 'en'
    const langObj = {
      lang: routeLang,
      name: `${routeName}__${routeLang}`,
    }

    dispatch('setLanguage', langObj)
    await dispatch('getGlobalData')
  },
}

export const mutations = {
  SET_SHOW_CALENDLY_POPUP_STATE: (state, value) => {
    state.showCalendlyPopup = value
  },
  SET_LANGUAGE: (state, value) => {
    state.language = value
  },
  SET_GLOBAL_SECTIONS_DATA: (state, value) => {
    state.globalSectionsData = value
  },
  SET_GLOBAL_CONTACT_DATA: (state, value) => {
    state.globalContactData = value
  },
  SET_DATA_FETCHING: (state, value) => {
    state.dataFetching = value
  },
  SET_LANG_TO_CHANGE: (state, value) => {
    state.langToChange = value
  },
  SET_ENABLED_LOCALES: (state, value) => {
    state.enabledLocales = value
  },
}

const getQueryByLanguage = (lang, isGlobalSections = false) => {
  let query

  switch (lang) {
    case 'es':
      query = isGlobalSections ? GlobalSectionsQueryES : GlobalContactQueryES
      break
    case 'de':
      query = isGlobalSections ? GlobalSectionsQueryDE : GlobalContactQueryDE
      break
    case 'fr':
      query = isGlobalSections ? GlobalSectionsQueryFR : GlobalContactQueryFR
      break
    case 'pl':
      query = isGlobalSections ? GlobalSectionsQueryPL : GlobalContactQueryPL
      break
    default:
      query = isGlobalSections ? GlobalSectionsQueryEN : GlobalContactQueryEN
  }

  return query
}
