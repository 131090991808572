export const ROUTES = {
  HOMEPAGE: {
    title: 'Homepage',
    path: '',
    slug: 'homepage',
    en_ID: 'cG9zdDoxNTcw',
    fr_ID: 'cG9zdDoxNTcz',
    de_ID: 'cG9zdDoxNTc0',
    es_ID: 'cG9zdDoxNTc1',
    pl_ID: 'cG9zdDoxNjQ2',
    en_PATH: '',
    fr_PATH: 'fr',
    es_PATH: 'es',
    de_PATH: 'de',
    pl_PATH: 'pl',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: true,
      footerWithBackground: true,
      key: 'homepage',
    },
  },
  SALES_APP_3D: {
    title: '3D sales app',
    path: '3d-sales-app',
    slug: '3d_sales_app',
    en_PATH: '3d-sales-app',
    fr_PATH: 'fr/3d-sales-app',
    es_PATH: 'es/3d-sales-app',
    de_PATH: 'de/3d-sales-app',
    pl_PATH: 'pl/3d-sales-app',
    en_ID: 'cG9zdDoxNjI3',
    fr_ID: 'cG9zdDoxNjMw',
    de_ID: 'cG9zdDoxNjMx',
    es_ID: 'cG9zdDoxNjMy',
    pl_ID: 'cG9zdDoxNjQ4',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: true,
      footerWithBackground: true,
      key: 'salesApp3d',
    },
  },
  WEB_SALES_SOLUTION: {
    title: 'Web sales solution',
    path: 'web-sales-solution',
    slug: 'web_sales_solution',
    en_PATH: 'web-sales-solution',
    fr_PATH: 'fr/web-sales-solution',
    es_PATH: 'es/web-sales-solution',
    de_PATH: 'de/web-sales-solution',
    pl_PATH: 'pl/web-sales-solution',
    en_ID: 'cG9zdDoxNTgz',
    fr_ID: 'cG9zdDoxNTg3',
    de_ID: 'cG9zdDoxNTg4',
    es_ID: 'cG9zdDoxNTg5',
    pl_ID: 'cG9zdDoxNjUw',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: true,
      footerWithBackground: true,
      key: 'webSalesSolution',
    },
  },
  CASE_STUDIES: {
    title: 'Case studies',
    path: 'case-studies',
    slug: 'case_studies',
    en_PATH: 'case-studies',
    fr_PATH: 'fr/case-studies',
    es_PATH: 'es/case-studies',
    de_PATH: 'de/case-studies',
    pl_PATH: 'pl/case-studies',
    en_ID: 'cG9zdDoxNjE1',
    fr_ID: 'cG9zdDoxNjE5',
    de_ID: 'cG9zdDoxNjIx',
    es_ID: 'cG9zdDoxNjIw',
    pl_ID: 'cG9zdDoxNjQy',
    meta: {
      footerWithCalendly: false,
      footerWithForm: false,
      formWithTitle: false,
      footerWithBackground: false,
      key: 'caseStudies',
    },
  },
  SINGLE_PORTFOLIO: {
    title: 'Case study',
    path: 'case-study',
    slug: 'case_study',
    en_PATH: 'case-study',
    fr_PATH: 'fr/case-study',
    es_PATH: 'es/case-study',
    de_PATH: 'de/case-study',
    pl_PATH: 'pl/case-study',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: true,
      footerWithBackground: true,
    },
  },
  VIDEOS: {
    title: 'Videos',
    path: 'videos',
    slug: 'videos',
    en_PATH: 'videos',
    fr_PATH: 'fr/videos',
    es_PATH: 'es/videos',
    de_PATH: 'de/videos',
    pl_PATH: 'pl/videos',
    meta: {
      footerWithCalendly: false,
      footerWithForm: false,
      formWithTitle: true,
      footerWithBackground: false,
      id: 'cG9zdDoxMzE0',
    },
  },
  MORE_VIDEOS: {
    title: 'More videos',
    path: 'more-videos',
    slug: 'more-videos',
    en_PATH: 'more-videos',
    fr_PATH: 'fr/more-videos',
    es_PATH: 'es/more-videos',
    de_PATH: 'de/more-videos',
    pl_PATH: 'pl/more-videos',
    meta: {
      footerWithCalendly: false,
      footerWithForm: false,
      formWithTitle: true,
      footerWithBackground: false,
      id: 'cG9zdDoyMTg4',
    },
  },
  ABOUT_US: {
    title: 'About us',
    path: 'about-us',
    slug: 'about_us',
    en_PATH: 'about-us',
    fr_PATH: 'fr/about-us',
    es_PATH: 'es/about-us',
    de_PATH: 'de/about-us',
    pl_PATH: 'pl/about-us',
    en_ID: 'cG9zdDoxNTk0',
    fr_ID: 'cG9zdDoxNTk3',
    de_ID: 'cG9zdDoxNTk4',
    es_ID: 'cG9zdDoxNTk5',
    pl_ID: 'cG9zdDoxNjQw',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: true,
      footerWithBackground: true,
      key: 'aboutUs',
    },
  },
  CONTACT: {
    title: 'Contact',
    path: 'contact',
    slug: 'contact',
    en_PATH: 'contact',
    fr_PATH: 'fr/contact',
    es_PATH: 'es/contact',
    de_PATH: 'de/contact',
    pl_PATH: 'pl/contact',
    en_ID: 'cG9zdDoxNjA0',
    fr_ID: 'cG9zdDoxNjA5',
    de_ID: 'cG9zdDoxNjA3',
    es_ID: 'cG9zdDoxNjEx',
    pl_ID: 'cG9zdDoxNjQ0',
    meta: {
      footerWithCalendly: false,
      footerWithForm: true,
      formWithTitle: false,
      footerWithBackground: false,
      key: 'contact',
    },
  },
  PRIVACY_POLICY: {
    title: 'Privacy policy',
    path: 'privacy-policy',
    slug: 'privacy-policy',
    en_PATH: 'privacy-policy',
    fr_PATH: 'fr/privacy-policy',
    es_PATH: 'es/privacy-policy',
    de_PATH: 'de/privacy-policy',
    pl_PATH: 'pl/privacy-policy',
    meta: {
      footerWithCalendly: false,
      footerWithForm: false,
      formWithTitle: false,
      footerWithBackground: false,
      key: 'privacy-policy',
    },
  },
}
