import Vue from 'vue'
import Router from 'vue-router'

import { ROUTES } from './utils/routes.js'

Vue.use(Router)

export function createRouter(ssrContext, createDefaultRouter, routerOptions, config) {
  const options = routerOptions || createDefaultRouter(ssrContext, config).options

  const router = new Router({
    mode: 'history',
    ...options,
    routes: [
      {
        path: `/${ROUTES.HOMEPAGE.en_PATH}`,
        component: () => import('./pages/Homepage.vue').then((m) => m.default || m),
        name: `${ROUTES.HOMEPAGE.slug}__en`,
        meta: {
          slug: ROUTES.HOMEPAGE.slug,
          id: ROUTES.HOMEPAGE.en_ID,
          redirectPath: ROUTES.HOMEPAGE.path,
          ...ROUTES.HOMEPAGE.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.HOMEPAGE.fr_PATH}`,
        component: () => import('./pages/Homepage.vue').then((m) => m.default || m),
        name: `${ROUTES.HOMEPAGE.slug}__fr`,
        meta: {
          slug: ROUTES.HOMEPAGE.slug,
          id: ROUTES.HOMEPAGE.fr_ID,
          redirectPath: ROUTES.HOMEPAGE.path,
          ...ROUTES.HOMEPAGE.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.HOMEPAGE.de_PATH}`,
        component: () => import('./pages/Homepage.vue').then((m) => m.default || m),
        name: `${ROUTES.HOMEPAGE.slug}__de`,
        meta: {
          slug: ROUTES.HOMEPAGE.slug,
          id: ROUTES.HOMEPAGE.de_ID,
          redirectPath: ROUTES.HOMEPAGE.path,
          ...ROUTES.HOMEPAGE.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.HOMEPAGE.es_PATH}`,
        component: () => import('./pages/Homepage.vue').then((m) => m.default || m),
        name: `${ROUTES.HOMEPAGE.slug}__es`,
        meta: {
          slug: ROUTES.HOMEPAGE.slug,
          id: ROUTES.HOMEPAGE.es_ID,
          redirectPath: ROUTES.HOMEPAGE.path,
          ...ROUTES.HOMEPAGE.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.HOMEPAGE.pl_PATH}`,
        component: () => import('./pages/Homepage.vue').then((m) => m.default || m),
        name: `${ROUTES.HOMEPAGE.slug}__pl`,
        meta: {
          slug: ROUTES.HOMEPAGE.slug,
          id: ROUTES.HOMEPAGE.pl_ID,
          redirectPath: ROUTES.HOMEPAGE.path,
          ...ROUTES.HOMEPAGE.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.CONTACT.en_PATH}`,
        component: () => import('./pages/Contact.vue').then((m) => m.default || m),
        name: `${ROUTES.CONTACT.slug}__en`,

        meta: {
          id: ROUTES.CONTACT.en_ID,
          slug: ROUTES.CONTACT.slug,
          redirectPath: ROUTES.CONTACT.path,
          ...ROUTES.CONTACT.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.CONTACT.fr_PATH}`,
        component: () => import('./pages/Contact.vue').then((m) => m.default || m),
        name: `${ROUTES.CONTACT.slug}__fr`,

        meta: {
          id: ROUTES.CONTACT.fr_ID,
          slug: ROUTES.CONTACT.slug,
          redirectPath: ROUTES.CONTACT.path,
          ...ROUTES.CONTACT.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.CONTACT.de_PATH}`,
        component: () => import('./pages/Contact.vue').then((m) => m.default || m),
        name: `${ROUTES.CONTACT.slug}__de`,

        meta: {
          id: ROUTES.CONTACT.de_ID,
          slug: ROUTES.CONTACT.slug,
          redirectPath: ROUTES.CONTACT.path,
          ...ROUTES.CONTACT.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.CONTACT.es_PATH}`,
        component: () => import('./pages/Contact.vue').then((m) => m.default || m),
        name: `${ROUTES.CONTACT.slug}__es`,

        meta: {
          id: ROUTES.CONTACT.es_ID,
          slug: ROUTES.CONTACT.slug,
          redirectPath: ROUTES.CONTACT.path,
          ...ROUTES.CONTACT.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.CONTACT.pl_PATH}`,
        component: () => import('./pages/Contact.vue').then((m) => m.default || m),
        name: `${ROUTES.CONTACT.slug}__pl`,

        meta: {
          id: ROUTES.CONTACT.pl_ID,
          slug: ROUTES.CONTACT.slug,
          redirectPath: ROUTES.CONTACT.path,
          ...ROUTES.CONTACT.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.SALES_APP_3D.en_PATH}`,
        component: () => import('./pages/SalesApp3D.vue').then((m) => m.default || m),

        name: `${ROUTES.SALES_APP_3D.slug}__en`,
        meta: {
          id: ROUTES.SALES_APP_3D.en_ID,
          slug: ROUTES.SALES_APP_3D.slug,
          redirectPath: ROUTES.SALES_APP_3D.path,
          ...ROUTES.SALES_APP_3D.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.SALES_APP_3D.fr_PATH}`,
        component: () => import('./pages/SalesApp3D.vue').then((m) => m.default || m),

        name: `${ROUTES.SALES_APP_3D.slug}__fr`,
        meta: {
          id: ROUTES.SALES_APP_3D.fr_ID,
          slug: ROUTES.SALES_APP_3D.slug,
          redirectPath: ROUTES.SALES_APP_3D.path,
          ...ROUTES.SALES_APP_3D.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.SALES_APP_3D.de_PATH}`,
        component: () => import('./pages/SalesApp3D.vue').then((m) => m.default || m),

        name: `${ROUTES.SALES_APP_3D.slug}__de`,
        meta: {
          id: ROUTES.SALES_APP_3D.de_ID,
          slug: ROUTES.SALES_APP_3D.slug,
          redirectPath: ROUTES.SALES_APP_3D.path,
          ...ROUTES.SALES_APP_3D.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.SALES_APP_3D.es_PATH}`,
        component: () => import('./pages/SalesApp3D.vue').then((m) => m.default || m),

        name: `${ROUTES.SALES_APP_3D.slug}__es`,
        meta: {
          id: ROUTES.SALES_APP_3D.es_ID,
          slug: ROUTES.SALES_APP_3D.slug,
          redirectPath: ROUTES.SALES_APP_3D.path,
          ...ROUTES.SALES_APP_3D.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.SALES_APP_3D.pl_PATH}`,
        component: () => import('./pages/SalesApp3D.vue').then((m) => m.default || m),

        name: `${ROUTES.SALES_APP_3D.slug}__pl`,
        meta: {
          id: ROUTES.SALES_APP_3D.pl_ID,
          slug: ROUTES.SALES_APP_3D.slug,
          redirectPath: ROUTES.SALES_APP_3D.path,
          ...ROUTES.SALES_APP_3D.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.WEB_SALES_SOLUTION.en_PATH}`,
        component: () => import('./pages/WebSalesSolution.vue').then((m) => m.default || m),

        name: `${ROUTES.WEB_SALES_SOLUTION.slug}__en`,
        meta: {
          id: ROUTES.WEB_SALES_SOLUTION.en_ID,
          slug: ROUTES.WEB_SALES_SOLUTION.slug,
          redirectPath: ROUTES.WEB_SALES_SOLUTION.path,
          ...ROUTES.WEB_SALES_SOLUTION.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.WEB_SALES_SOLUTION.fr_PATH}`,
        component: () => import('./pages/WebSalesSolution.vue').then((m) => m.default || m),

        name: `${ROUTES.WEB_SALES_SOLUTION.slug}__fr`,
        meta: {
          id: ROUTES.WEB_SALES_SOLUTION.fr_ID,
          slug: ROUTES.WEB_SALES_SOLUTION.slug,
          redirectPath: ROUTES.WEB_SALES_SOLUTION.path,
          ...ROUTES.WEB_SALES_SOLUTION.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.WEB_SALES_SOLUTION.de_PATH}`,
        component: () => import('./pages/WebSalesSolution.vue').then((m) => m.default || m),

        name: `${ROUTES.WEB_SALES_SOLUTION.slug}__de`,
        meta: {
          id: ROUTES.WEB_SALES_SOLUTION.de_ID,
          slug: ROUTES.WEB_SALES_SOLUTION.slug,
          redirectPath: ROUTES.WEB_SALES_SOLUTION.path,
          ...ROUTES.WEB_SALES_SOLUTION.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.WEB_SALES_SOLUTION.es_PATH}`,
        component: () => import('./pages/WebSalesSolution.vue').then((m) => m.default || m),

        name: `${ROUTES.WEB_SALES_SOLUTION.slug}__es`,
        meta: {
          id: ROUTES.WEB_SALES_SOLUTION.es_ID,
          slug: ROUTES.WEB_SALES_SOLUTION.slug,
          redirectPath: ROUTES.WEB_SALES_SOLUTION.path,
          ...ROUTES.WEB_SALES_SOLUTION.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.WEB_SALES_SOLUTION.pl_PATH}`,
        component: () => import('./pages/WebSalesSolution.vue').then((m) => m.default || m),

        name: `${ROUTES.WEB_SALES_SOLUTION.slug}__pl`,
        meta: {
          id: ROUTES.WEB_SALES_SOLUTION.pl_ID,
          slug: ROUTES.WEB_SALES_SOLUTION.slug,
          redirectPath: ROUTES.WEB_SALES_SOLUTION.path,
          ...ROUTES.WEB_SALES_SOLUTION.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.CASE_STUDIES.en_PATH}`,
        component: () => import('./pages/CaseStudies.vue').then((m) => m.default || m),

        name: `${ROUTES.CASE_STUDIES.slug}__en`,
        meta: {
          id: ROUTES.CASE_STUDIES.en_ID,
          slug: ROUTES.CASE_STUDIES.slug,
          redirectPath: ROUTES.CASE_STUDIES.path,
          ...ROUTES.CASE_STUDIES.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.CASE_STUDIES.fr_PATH}`,
        component: () => import('./pages/CaseStudies.vue').then((m) => m.default || m),

        name: `${ROUTES.CASE_STUDIES.slug}__fr`,
        meta: {
          id: ROUTES.CASE_STUDIES.fr_ID,
          slug: ROUTES.CASE_STUDIES.slug,
          redirectPath: ROUTES.CASE_STUDIES.path,
          ...ROUTES.CASE_STUDIES.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.CASE_STUDIES.de_PATH}`,
        component: () => import('./pages/CaseStudies.vue').then((m) => m.default || m),

        name: `${ROUTES.CASE_STUDIES.slug}__de`,
        meta: {
          id: ROUTES.CASE_STUDIES.de_ID,
          slug: ROUTES.CASE_STUDIES.slug,
          redirectPath: ROUTES.CASE_STUDIES.path,
          ...ROUTES.CASE_STUDIES.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.CASE_STUDIES.es_PATH}`,
        component: () => import('./pages/CaseStudies.vue').then((m) => m.default || m),

        name: `${ROUTES.CASE_STUDIES.slug}__es`,
        meta: {
          id: ROUTES.CASE_STUDIES.es_ID,
          slug: ROUTES.CASE_STUDIES.slug,
          redirectPath: ROUTES.CASE_STUDIES.path,
          ...ROUTES.CASE_STUDIES.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.CASE_STUDIES.pl_PATH}`,
        component: () => import('./pages/CaseStudies.vue').then((m) => m.default || m),

        name: `${ROUTES.CASE_STUDIES.slug}__pl`,
        meta: {
          id: ROUTES.CASE_STUDIES.pl_ID,
          slug: ROUTES.CASE_STUDIES.slug,
          redirectPath: ROUTES.CASE_STUDIES.path,
          ...ROUTES.CASE_STUDIES.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.SINGLE_PORTFOLIO.en_PATH}/:slug/`,
        component: () => import('./pages/SinglePortfolio.vue').then((m) => m.default || m),

        name: `${ROUTES.SINGLE_PORTFOLIO.slug}__en`,
        meta: {
          slug: ROUTES.SINGLE_PORTFOLIO.slug,
          redirectPath: ROUTES.SINGLE_PORTFOLIO.path,
          ...ROUTES.SINGLE_PORTFOLIO.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.SINGLE_PORTFOLIO.fr_PATH}/:slug/`,
        component: () => import('./pages/SinglePortfolio.vue').then((m) => m.default || m),

        name: `${ROUTES.SINGLE_PORTFOLIO.slug}__fr`,
        meta: {
          slug: ROUTES.SINGLE_PORTFOLIO.slug,
          redirectPath: ROUTES.SINGLE_PORTFOLIO.path,
          ...ROUTES.SINGLE_PORTFOLIO.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.SINGLE_PORTFOLIO.de_PATH}/:slug/`,
        component: () => import('./pages/SinglePortfolio.vue').then((m) => m.default || m),

        name: `${ROUTES.SINGLE_PORTFOLIO.slug}__de`,
        meta: {
          slug: ROUTES.SINGLE_PORTFOLIO.slug,
          redirectPath: ROUTES.SINGLE_PORTFOLIO.path,
          ...ROUTES.SINGLE_PORTFOLIO.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.SINGLE_PORTFOLIO.es_PATH}/:slug/`,
        component: () => import('./pages/SinglePortfolio.vue').then((m) => m.default || m),

        name: `${ROUTES.SINGLE_PORTFOLIO.slug}__es`,
        meta: {
          slug: ROUTES.SINGLE_PORTFOLIO.slug,
          redirectPath: ROUTES.SINGLE_PORTFOLIO.path,
          ...ROUTES.SINGLE_PORTFOLIO.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.SINGLE_PORTFOLIO.pl_PATH}/:slug/`,
        component: () => import('./pages/SinglePortfolio.vue').then((m) => m.default || m),

        name: `${ROUTES.SINGLE_PORTFOLIO.slug}__pl`,
        meta: {
          slug: ROUTES.SINGLE_PORTFOLIO.slug,
          redirectPath: ROUTES.SINGLE_PORTFOLIO.path,
          ...ROUTES.SINGLE_PORTFOLIO.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.ABOUT_US.path}/`,
        component: () => import('./pages/AboutUs.vue').then((m) => m.default || m),

        name: `${ROUTES.ABOUT_US.slug}__en`,
        meta: {
          id: ROUTES.ABOUT_US.en_ID,
          slug: ROUTES.ABOUT_US.slug,
          redirectPath: ROUTES.ABOUT_US.path,
          ...ROUTES.ABOUT_US.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.ABOUT_US.fr_PATH}/`,
        component: () => import('./pages/AboutUs.vue').then((m) => m.default || m),

        name: `${ROUTES.ABOUT_US.slug}__fr`,
        meta: {
          id: ROUTES.ABOUT_US.fr_ID,
          slug: ROUTES.ABOUT_US.slug,
          redirectPath: ROUTES.ABOUT_US.path,
          ...ROUTES.ABOUT_US.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.ABOUT_US.de_PATH}/`,
        component: () => import('./pages/AboutUs.vue').then((m) => m.default || m),

        name: `${ROUTES.ABOUT_US.slug}__de`,
        meta: {
          id: ROUTES.ABOUT_US.de_ID,
          slug: ROUTES.ABOUT_US.slug,
          redirectPath: ROUTES.ABOUT_US.path,
          ...ROUTES.ABOUT_US.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.ABOUT_US.es_PATH}/`,
        component: () => import('./pages/AboutUs.vue').then((m) => m.default || m),

        name: `${ROUTES.ABOUT_US.slug}__es`,
        meta: {
          id: ROUTES.ABOUT_US.es_ID,
          slug: ROUTES.ABOUT_US.slug,
          redirectPath: ROUTES.ABOUT_US.path,
          ...ROUTES.ABOUT_US.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.ABOUT_US.pl_PATH}/`,
        component: () => import('./pages/AboutUs.vue').then((m) => m.default || m),

        name: `${ROUTES.ABOUT_US.slug}__pl`,
        meta: {
          id: ROUTES.ABOUT_US.pl_ID,
          slug: ROUTES.ABOUT_US.slug,
          redirectPath: ROUTES.ABOUT_US.path,
          ...ROUTES.ABOUT_US.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.VIDEOS.en_PATH}`,
        component: () => import('./pages/Videos.vue').then((m) => m.default || m),

        name: `${ROUTES.VIDEOS.slug}__en`,
        meta: {
          slug: ROUTES.VIDEOS.slug,
          redirectPath: ROUTES.VIDEOS.path,
          ...ROUTES.VIDEOS.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.VIDEOS.fr_PATH}`,
        component: () => import('./pages/Videos.vue').then((m) => m.default || m),

        name: `${ROUTES.VIDEOS.slug}__fr`,
        meta: {
          slug: ROUTES.VIDEOS.slug,
          redirectPath: ROUTES.VIDEOS.path,
          ...ROUTES.VIDEOS.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.VIDEOS.de_PATH}`,
        component: () => import('./pages/Videos.vue').then((m) => m.default || m),

        name: `${ROUTES.VIDEOS.slug}__de`,
        meta: {
          slug: ROUTES.VIDEOS.slug,
          redirectPath: ROUTES.VIDEOS.path,
          ...ROUTES.VIDEOS.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.VIDEOS.es_PATH}`,
        component: () => import('./pages/Videos.vue').then((m) => m.default || m),

        name: `${ROUTES.VIDEOS.slug}__es`,
        meta: {
          slug: ROUTES.VIDEOS.slug,
          redirectPath: ROUTES.VIDEOS.path,
          ...ROUTES.VIDEOS.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.VIDEOS.pl_PATH}`,
        component: () => import('./pages/Videos.vue').then((m) => m.default || m),

        name: `${ROUTES.VIDEOS.slug}__pl`,
        meta: {
          slug: ROUTES.VIDEOS.slug,
          redirectPath: ROUTES.VIDEOS.path,
          ...ROUTES.VIDEOS.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.MORE_VIDEOS.en_PATH}`,
        component: () => import('./pages/MoreVideos.vue').then((m) => m.default || m),

        name: `${ROUTES.MORE_VIDEOS.slug}__en`,
        meta: {
          slug: ROUTES.MORE_VIDEOS.slug,
          redirectPath: ROUTES.MORE_VIDEOS.path,
          ...ROUTES.MORE_VIDEOS.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.MORE_VIDEOS.fr_PATH}`,
        component: () => import('./pages/MoreVideos.vue').then((m) => m.default || m),

        name: `${ROUTES.MORE_VIDEOS.slug}__fr`,
        meta: {
          slug: ROUTES.MORE_VIDEOS.slug,
          redirectPath: ROUTES.MORE_VIDEOS.path,
          ...ROUTES.MORE_VIDEOS.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.MORE_VIDEOS.de_PATH}`,
        component: () => import('./pages/MoreVideos.vue').then((m) => m.default || m),

        name: `${ROUTES.MORE_VIDEOS.slug}__de`,
        meta: {
          slug: ROUTES.MORE_VIDEOS.slug,
          redirectPath: ROUTES.MORE_VIDEOS.path,
          ...ROUTES.MORE_VIDEOS.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.MORE_VIDEOS.es_PATH}`,
        component: () => import('./pages/MoreVideos.vue').then((m) => m.default || m),

        name: `${ROUTES.MORE_VIDEOS.slug}__es`,
        meta: {
          slug: ROUTES.MORE_VIDEOS.slug,
          redirectPath: ROUTES.MORE_VIDEOS.path,
          ...ROUTES.MORE_VIDEOS.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.MORE_VIDEOS.pl_PATH}`,
        component: () => import('./pages/MoreVideos.vue').then((m) => m.default || m),

        name: `${ROUTES.MORE_VIDEOS.slug}__pl`,
        meta: {
          slug: ROUTES.MORE_VIDEOS.slug,
          redirectPath: ROUTES.MORE_VIDEOS.path,
          ...ROUTES.MORE_VIDEOS.meta,
          lang: 'pl',
        },
      },
      {
        path: `/${ROUTES.PRIVACY_POLICY.en_PATH}`,
        component: () => import('./pages/PrivacyPolicy.vue').then((m) => m.default || m),

        name: `${ROUTES.PRIVACY_POLICY.slug}__en`,
        meta: {
          slug: ROUTES.PRIVACY_POLICY.slug,
          redirectPath: ROUTES.PRIVACY_POLICY.path,
          ...ROUTES.PRIVACY_POLICY.meta,
          lang: 'en',
        },
      },
      {
        path: `/${ROUTES.PRIVACY_POLICY.fr_PATH}`,
        component: () => import('./pages/PrivacyPolicy.vue').then((m) => m.default || m),

        name: `${ROUTES.PRIVACY_POLICY.slug}__fr`,
        meta: {
          slug: ROUTES.PRIVACY_POLICY.slug,
          redirectPath: ROUTES.PRIVACY_POLICY.path,
          ...ROUTES.PRIVACY_POLICY.meta,
          lang: 'fr',
        },
      },
      {
        path: `/${ROUTES.PRIVACY_POLICY.de_PATH}`,
        component: () => import('./pages/PrivacyPolicy.vue').then((m) => m.default || m),

        name: `${ROUTES.PRIVACY_POLICY.slug}__de`,
        meta: {
          slug: ROUTES.PRIVACY_POLICY.slug,
          redirectPath: ROUTES.PRIVACY_POLICY.path,
          ...ROUTES.PRIVACY_POLICY.meta,
          lang: 'de',
        },
      },
      {
        path: `/${ROUTES.PRIVACY_POLICY.es_PATH}`,
        component: () => import('./pages/PrivacyPolicy.vue').then((m) => m.default || m),

        name: `${ROUTES.PRIVACY_POLICY.slug}__es`,
        meta: {
          slug: ROUTES.PRIVACY_POLICY.slug,
          redirectPath: ROUTES.PRIVACY_POLICY.path,
          ...ROUTES.PRIVACY_POLICY.meta,
          lang: 'es',
        },
      },
      {
        path: `/${ROUTES.PRIVACY_POLICY.pl_PATH}`,
        component: () => import('./pages/PrivacyPolicy.vue').then((m) => m.default || m),

        name: `${ROUTES.PRIVACY_POLICY.slug}__pl`,
        meta: {
          slug: ROUTES.PRIVACY_POLICY.slug,
          redirectPath: ROUTES.PRIVACY_POLICY.path,
          ...ROUTES.PRIVACY_POLICY.meta,
          lang: 'pl',
        },
      },
    ],
  })

  if (process.client) {
    router.beforeEach((to, from, next) => {
      const videoPlayers = document.querySelectorAll('video')
      videoPlayers.forEach((element) => {
        element.pause()
        element.src = ''
      })

      next()
    })
  }

  router.afterEach((a, b) => {
    if (a.path !== b.path && b.name !== null) {
      if (typeof window.gtag !== 'undefined' && process.client) {
        window.gtag('config', 'AW-380967499')
        window.gtag('config', 'UA-164290922-1')
      }
    }
  })

  return router
}
